import {Controller} from "stimulus";

export default class extends Controller {
    path;
    device_id;
    id;
    form;
    connect() {
        this.path = this.data.get("url")
        this.device_id = this.data.get("device_id")
        this.id = this.data.get("id")

        this.form =  $("#new_lockout_form")
        const startField = this.form.find('input[name="device_lockout[start]"]')
        const endField = this.form.find('input[name="device_lockout[end]"]')
        startField.timepicker({})
        endField.timepicker({})
        this.getLockouts()
        this.setValidator()

    }

    setValidator = () => {
        $.validator.addMethod("lessThan", (value,element,param)=>{
            const curr = moment(value , "hh:mm A")
            const paramvalue = $(param).val()
            const compare = moment(paramvalue, "hh:mm A")
            return curr.isBefore(compare)
        },"Start Value has to be less than end value")
        $.validator.addMethod("greaterThan", (value,element,param)=>{
            const curr = moment(value, "hh:mm A")
            const paramvalue = $(param).val()
            const compare = moment(paramvalue, "hh:mm A")
            return curr.isAfter(compare)

        }, "End Value has to be greater than start value")
        this.form.validate({
            rules: {
                "device_lockout[start]": {required: true },
                "device_lockout[end]": {required: true  },
            },
            errorClass: "error text-red-md ml-2 text-sm",
            errorElement: "div",
            validClass: 'success',
            errorElementClass:"em",
            highlight: function(element, errorClass, validClass) {
                $(element).parents("div.control-group").addClass(errorClass).removeClass(validClass);
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).parents(".error").removeClass(errorClass).addClass(validClass);
            },
            submitHandler: (thisForm) => {
                this.createLockout()
                return false
            },

        });
    }
    createLockout = () => {
        const data = this.form.serializeArray()
        $.ajax({
            type: "post",
            url: `${this.path}`,
            enctype: 'multipart/form-data',
            dataType: "json",
            data: data,
            success: () => {
                this.getLockouts()
                this.form[0].reset()
            },
            error: (x) => {
                toastr.error(x)
            }
        })
    }
    getLockouts = () => {
        const controller = this
        $.ajax({
            type: "get",
            url: this.path,
            data: {
                device_id: controller.device_id
            },
            dataType: 'json',
            success: (d, t, x) => {
                controller.generateHtml(d)
            },
            error: (x, a ,b) => {
                toastr.error(b)
            }
        })
    }
    deleteLockout = (id) => {
        $.ajax({
            type: "delete",
            url: `${this.path}/${id}`,
            dataType: "json",
            success: () => {
                $(`[data-device-lockouts-list-item="${id}"]`).remove()
            },
            error: (x,a,b) => {
                toastr.error(b)
            }
        })
    }
    generateHtml = (lockouts) => {
        const container = $(`[data-device-lockouts-list-id=${this.id}]`)
        container.empty()
        if(lockouts.length === 0){
            container.appendTo("<p>This Device does not have any lockout times set</p>")
            return;
        }
        lockouts.forEach(item => {
            const htmlItem = `
                <div class="flex-grow basis-full flex flex-nowrap" data-device-lockouts-list-item="${item.id}" >
                    <div class="flex-grow basis-full flex flex-nowrap items-center px-4">${moment(item.start).format("hh:mm A")}</div>
                    <div class="flex-grow basis-full flex flex-nowrap items-center px-4">${moment(item.end).format("hh:mm A")}</div>
                    <div class="flex-shrink basis-full flex flex-nowrap items-center basis-0">
                        <button type="button" title="Delete Lockout" id="btn_${item.id}" >
                            <i class="fa fa-delete-left text-red-md"></i>
                        </button>
                    </div>
                </div>`
            container.append(htmlItem)
            container
                .find(`#btn_${item.id} `)
                .click(() => {
                    this.deleteLockout(item.id)
                })
        })
    }
}