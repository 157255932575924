import {Controller} from "stimulus"
import {
    BarcodeFormat,
    BrowserMultiFormatReader
} from "@zxing/browser";
import {DecodeHintType} from "@zxing/library";

export default class extends Controller {
    codeReader;
    reader;
    formats = [BarcodeFormat.CODE_128, BarcodeFormat.AZTEC];

    connect() {
        const devices = this.data.get("devices");
        this.devices = devices ? devices.split(",") : [];
        const hints = new Map()
        hints.set(DecodeHintType.POSSIBLE_FORMATS, this.formats)
        this.codeReader = new BrowserMultiFormatReader(
            hints,
            {delayBetweenScanAttempts:1000}
        )


        this.init();

    }

    init = async () => {
        try {
            if (!navigator.mediaDevices || typeof navigator.mediaDevices.getUserMedia !== "function") {

                throw new Error("NoDevicesEnabled")
            }
            const enumeratedDevices = await navigator.mediaDevices.enumerateDevices()

            const videoDevices = enumeratedDevices.filter(device => device.kind === 'videoinput');

            if (!videoDevices) {
                throw new Error("NoDevicesFound")
            }
            this.defaultDevice = videoDevices.find(device => /back/.test(device.label)) || videoDevices[0]
            if (!this.defaultDevice) {
                throw new Error("NoDevices")
            }
            this.devices = videoDevices;

            const reader = await this.codeReader

                .decodeOnceFromVideoDevice(
                    this.defaultDevice.id,
                    this.element
                )

            this.handleResult(reader);
            // setTimeout(() => this.reader.stop(), 20000);
        } catch (err) {

            this.handleError(err);

        }
    }
    handleError = (err) => {
        const event = new CustomEvent("Error", {
            detail: err,
            bubbles: false,
            cancelable: true,
            composed: false,
        })
        this.element.dispatchEvent(event)
    }
    handleResult = (result)=>{
        const event = new CustomEvent("Result", {
            detail: result.text,
            bubbles: false,
            cancelable: true,
            composed: false,
        })
        this.element.dispatchEvent(event)
    }

}