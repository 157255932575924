// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
import "@fortawesome/fontawesome-free/js/all";
import LocalTime from "local-time"
import 'daterangepicker';

import 'jquery-ui';
import 'jquery-ui-touch-punch';
import 'jquery-timepicker/jquery.timepicker.js';
import {JqueryValidator} from "./jqueryValidator";
import toastr from 'toastr';
import * as Video from 'twilio-video'

import '../stylesheets/application.tailwind.scss'


LocalTime.start()
Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.toastr = toastr
window.Video = Video

new JqueryValidator();











