import {Controller} from "stimulus"


export default class extends Controller {
    isVisible = false;
    freezeOnEnd = false;
    disabled = false;
    connect() {
        const controller = this;
        this.id = this.data.get("id");
        this.freezeOnEnd = this.data.get("freezeonend")
        this.disabled = this.data.get('disabled') || 'false'
        this.initTrigger();
        const isVisible = $(`#${this.id}`).is(":visible");
        this.createDraggable(isVisible, this.freezeOnEnd);

        $(window).resize(function(){
            const isVisible = $(`#${controller.id}`).is(":visible");
            controller.createDraggable(isVisible);

        })
    }

    initTrigger() {
        const controller = this;
        $(this.element)
            .find(`#${controller.id}_trigger`)
            .click(() => {
                controller.dispatch();
            })
    }

    dispatch() {
        const event = new CustomEvent("Dismiss", {
            detail: this.id,
            bubbles: true,
            cancelable: true,
            composed: false,
        })
        this.element.dispatchEvent(event)
    }

    createDraggable(isVisible, freezeOnEnd) {
        const controller = this;
        if(isVisible && !controller.isVisible) {
            controller.isVisible = isVisible;
            const ele = $(`#${controller.id}`)

            $(document).on('Release', function(event){

                if(event.originalEvent.detail.id === controller.id) {
                    ele.draggable("option", "revert", true);
                    ele.draggable( "option", "disabled", false );
                    ele.animate({left:"0px"})
                }
            })
            if(this.disabled === 'true'){ele.addClass("disabled")}
            let to;
            ele.draggable({
                    axis: "x",
                    revert: true,
                    scroll: false,
                    disabled: this.disabled === 'true',
                    containment: $(controller.element),
                    drag: function(event, pos){
                        const parentWidth = ele.parent().width();
                        const w = ele.width();

                        if(w+pos.position.left +1 >= parentWidth) {

                            if(freezeOnEnd === 'true') {
                                ele.draggable("option", "revert", false);
                                ele.draggable("option", "disabled", true );
                                if(freezeOnEnd === 'true'){
                                    if(to){clearTimeout(to)}
                                    to = setTimeout(()=>{
                                        ele.draggable("option", "revert", true);
                                        ele.draggable("option", "disabled", false );
                                        ele.animate({left:"0px"})

                                    },10000)
                                }

                            }
                            return true;
                        }
                    },
                    stop: function (event, pos) {
                        const parentWidth = ele.parent().width();
                        const w = ele.width();
                        if(w+pos.position.left +1 >= parentWidth) {
                            controller.dispatch()
                            return true;
                        }

                    },
                }
            )

        }
        else if(!isVisible && controller.isVisible){
            controller.isVisible = isVisible;
            // remove draggable
        }
    }
}