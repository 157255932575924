import {Controller} from "stimulus"
import {
    connect
} from "twilio-video"

export default class extends Controller {
    aspectRatio = 0.66667 //-> 1280 / 1920
    static targets = ["local", "remote", "end", "spinner"]

    connect() {
        this.slug = this.data.get("slug")
        this.token = this.data.get("token")
        this.isTenant = this.data.get("istenant") === 'true';
        this.tenantPath = this.data.get("tenantpath")
        // window.gonative_library_ready = () => {
        //     gonative.twilio.video.join({
        //         'displayName': this.slug,
        //         'token': this.token,
        //         'identity': 'identity',
        //         'callback': this.gnCallback
        //     })
        // }
        setTimeout(() => {
                this.joinRoom()
        }, 2000)
    }

    get localEle() {
        return this.localTarget
    }

    get remoteEle() {
        return this.remoteTarget
    }

    get endButtonEle() {
        return this.endTarget
    }

    get spinnerEle(){
        return this.spinnerTarget
    }

    gnCallback = (data) => {
        if (data.error) {
            return toastr.error(data.error)
        }
        toastr.success(data.status)
    }
    joinRoom = async () => {
        try {
            const localTracks = await Video.createLocalTracks({
                audio: true,
                video: {
                    frameRate: 24,

                }
            })
            const localMediaContainer = this.localEle
            localTracks.forEach(localTrack => {
                localMediaContainer.append(localTrack.attach())
            })
            this.room = await connect(
                this.token,
                {
                    name: this.slug,
                    tracks: localTracks,
                    bandwidthProfile: {
                        video: {
                            mode: 'collaboration',
                            clientTrackSwitchOffControl: "manual",
                            dominantSpeakerPriority: 'high',
                            contentPreferencesMode: "auto"
                        }
                    },
                    dominantSpeaker: true,
                    maxAudioBitrate: 16000,
                    preferredVideoCodecs: [{codec: 'VP8', simulcast: true}],
                    networkQuality: {local: 1, remote: 4}

                })
            this.participants = this.room.participants || []
            this.participants.forEach(this.onParticipantConnected)
            this.room.on("participantConnected", this.onParticipantConnected);
            this.room.on("participantDisconnected", this.onParticipantDisconnected);
            $(this.endButtonEle)
                .removeClass("hidden")
                .on("click", () => {
                    this.onLeaveButtonClick(this.room)
                })

        } catch (error) {
            console.log(error)
            toastr.error(error.message)
        }
    }
    onParticipantConnected = (participant) => {
        const container = document.createElement("div")
        container.id = participant.sid;
        container.style.height = "100%";
        container.style.width = "100%";
        participant.on(
            "trackSubscribed",
            (track) => {
                this.trackSubscribed(container, track)
            }
        );
        participant.on("trackUnsubscribed", this.trackUnsubscribed);
        participant.tracks.forEach(publication => {
            if (publication.isSubscribed) {
                this.trackSubscribed(publication.track)
            }
        })
        $(this.remoteEle).append(container);

        // set video to middle if screen is small
        const viewport_w = document.documentElement.clientWidth;
        const remote_div_w = $(this.remoteEle).width();
        if (viewport_w < remote_div_w) {
           // $(this.remoteEle).css("margin-left", "-" + (remote_div_w - viewport_w) / 2 + "px")
        }
    }
    trackSubscribed = (container, track) => {
        $(this.spinnerEle).addClass("hidden")
        container.appendChild(track.attach());
    };
    trackUnsubscribed = (track) => {
        track.detach().forEach((element) => element.remove());
    };
    onParticipantDisconnected = function (participant) {
        const participantDiv = document.getElementById(participant.sid);
        participantDiv.parentNode.removeChild(participantDiv);
    };
    onLeaveButtonClick = (room) => {
        room.localParticipant.tracks.forEach((publication) => {
            const track = publication.track;
            track.stop();
            const elements = track.detach();
            elements.forEach((element) => element.remove());
        });
        room.disconnect();

        if (this.isTenant) {
            window.location = this.tenantPath;
        } else {
            window.history.back();
        }
    };
}