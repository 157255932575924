import {Controller} from "stimulus"
import Quagga from "@ericblade/quagga2";

export default class extends Controller {
    callback = (err, result) => {
        if (err) {
            return this.handleError(err);
        }
        const event = new CustomEvent("Result", {
            detail: result,
            bubbles: false,
            cancelable: true,
            composed: false,
        })
        this.element.dispatchEvent(event)
    }

    handleError = (err) => {
        const event = new CustomEvent("Error", {
            detail: err,
            bubbles: false,
            cancelable: true,
            composed: false,
        })
        this.element.dispatchEvent(event)
    }
    deepMerge = (target, ...sources) => {
        const isObject = (item) => {
            return (item && typeof item === 'object' && !Array.isArray(item));
        }

        if (!sources.length) return target;
        const source = sources.shift();

        if (isObject(target) && isObject(source)) {
            for (const key in source) {
                if (isObject(source[key])) {
                    if (!target[key]) Object.assign(target, {
                        [key]: {}
                    });
                    this.deepMerge(target[key], source[key]);
                } else {
                    Object.assign(target, {
                        [key]: source[key]
                    });
                }
            }
        }

        return this.deepMerge(target, ...sources);

    }
    orderByOccurence = (arr) => {
        const counts = {};
        arr.forEach((v) => {
            if (!counts[v]) {
                counts[v] === 0
            }
            counts[v]++
        })
        return Object.keys(counts).sort((c, n) => {
            return counts[c] < counts[n]
        });
    }
    defaultDevice='';
    state = {
        lastCode: [],
        numOfWorkers: navigator.hardwareConcurrency,
        frequency: 5,
        locate: true,
        locator: {
            halfSample: false,
            patchSize: "x-large", // x-small, small, medium, large, x-large
            debug: {
                showCanvas: false,
                showPatches: false,
                showFoundPatches: false,
                showSkeleton: false,
                showLabels: false,
                showPatchLabels: false,
                showRemainingPatchLabels: false,
                boxFromPatches: {
                    showTransformed: false,
                    showTransformedBox: false,
                    showBB: false
                }
            }
        },
        inputStream: {
            name: "Live",
            type: "LiveStream", //ImageStream, VideoStream, LiveStream
            target: this.element,
            constraints: {
                width: {min: 640},
                height: {min: 400},
                facingMode: "environment",
                aspectRatio: {min: 1, max: 3}

            },
            area: { // defines rectangle of the detection/localization area
                top: "10%",    // top offset
                right: "0%",  // right offset
                left: "0%",   // left offset
                bottom: "10%"  // bottom offset
            },
            singleChannel: false
        },
        debug: {
            showCanvas: false,
            showPatches: false,
            showFoundPatches: false,
            showSkeleton: false,
            showLabels: false,
            showPatchLabels: false,
            showRemainingPatchLabels: false,
            boxFromPatches: {
                showTransformed: false,
                showTransformedBox: false,
                showBB: false
            }
        },
        decoder: {
            readers: [
                'code_128_reader',
                //'ean_reader',
                //'ean_reader',
                //'ean_8_reader',
                //'code_39_reader',
                //'code_39_vin_reader',
                //'codabar_reader',
                //'upc_reader',
                //'upc_e_reader',
                //'i2of5_reader',
                //'2of5_reader',
                //'code_93_reader',
            ],
            debug: {
                drawBoundingBox: false,
                showFrequency: false,
                drawScanline: false,
                showPattern: false
            },
            multiple: false
        }
    }

    connect() {

        const state = this.data.get("state") || '{}';

        const devices = this.data.get("devices");

        this.devices = devices ? devices.split(",") : [];

        this.state = this.deepMerge(this.state, JSON.parse(state));
        this.init()


    }

    init = async () => {
        try {

            if (!navigator.mediaDevices || typeof navigator.mediaDevices.getUserMedia !== "function") {

                throw new Error("NoDevicesEnabled")
            }
            const enumeratedDevices = await navigator.mediaDevices.enumerateDevices()

            const videoDevices = enumeratedDevices.filter(device => device.kind === 'videoinput');

            if (!videoDevices) {
                throw new Error("NoDevicesFound")
            }
            this.defaultDevice = videoDevices.find(device=>/back/.test(device.label)) || videoDevices[0]

            if (!this.defaultDevice) {
                throw new Error("NoDevices")
            }
            this.devices = videoDevices;
            this.state.inputStream.constraints.deviceId = this.defaultDevice.id;

            Quagga.init(
                {...this.state},
                function (err) {
                    if (err) {
                        return this.handleError(err);
                    }
                    Quagga.start()
                })
            Quagga.onProcessed((result) => {
                const drawingCtx = Quagga.canvas.ctx.overlay;
                const drawingCanvas = Quagga.canvas.dom.overlay;

                if (result) {
                    if (result.boxes) {
                        drawingCtx.clearRect(0, 0,
                            parseInt(drawingCanvas.getAttribute("width")),
                            parseInt(drawingCanvas.getAttribute("height")));
                        result.boxes.filter(function (box) {
                            return box !== result.box;
                        }).forEach(function (box) {

                            Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "#52BF04", lineWidth: 2});
                        });
                    }
                    if (result.box) {
                        Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#999", lineWidth: 2});
                    }

                    if (result.codeResult && result.codeResult.code) {
                        Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {
                            color: 'red',
                            lineWidth: 3
                        });
                    }
                }

            })
            Quagga.onDetected((result) => {
                const {code} = result.codeResult
                // help with debounce
                if (this.state.lastCode.length >= 10) {
                    const sortedCode = this.orderByOccurence(this.state.lastCode);
                    this.callback(null, sortedCode[0]);
                    this.state.lastCode = [];
                }
                this.state.lastCode.push(code);

            })
        } catch (err) {
            this.handleError(err)

        }
    }
}