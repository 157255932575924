import {Controller} from "@hotwired/stimulus";
import {createWorker} from "tesseract.js";
import {BarcodeFormat, BrowserMultiFormatReader} from '@zxing/browser';
import axios from 'axios';
import {DecodeHintType} from "@zxing/library";


export default class extends Controller {
    splitTextArr = [];
    splitLineArr = [];
    imgurl = "";
    addresses = []
    static targets = [
        "address_id",
        "numeric",
        "city",
        "state",
        "zip",
        "street_name",
        "package_image", // image imput element
        "barcode", //barcode input
        "preview", //image element to preview,
        "barcode_clone",
        "submit_clone"
    ]
    connect() {
        this.property_id = this.data.get("property")
        this.addresses_url = this.data.get("addresses_url") + ".json"
        this.new_parcel_image_url = this.data.get("new_parcel_image_url")
        this.property_parcel_images_extract_text_path = this.data.get("property_parcel_images_extract_text_path")
        this.previewSrc = this.previewTarget.src;
        // set up file reader to set img component
        this.reader = new FileReader()
        this.reader.addEventListener("load", this.handleReaderLoad)
        this.barcode_cloneTarget.addEventListener('keyup', (e)=>{
            const type = this.barcode_cloneTarget.getAttribute("data-type");
            if(["ups", "fedex"].includes(type)) {
                this.barcode_cloneTarget.value = this.formatTracker(type, e.target.value)
            }
            this.barcodeTarget.value = e.target.value.replace(/\s/g, "")
        })

        this.submit_cloneTarget.addEventListener('click', ()=>{
            const parcelForm = this.element.querySelector("#parcel-form")
            $("#shipitWaiter").removeClass("hidden")
            parcelForm.submit()
        })
        // handle button div
        const uploadButton = this.element.querySelector("#btn");
        uploadButton.innerText = this.isMobile() ? "Take Picture" : "Upload File";
        uploadButton.addEventListener("click", () => {
            this.element.querySelector("#img").click()
        })
        // handle change on the upload image component
        this.package_imageTarget.addEventListener("change", this.handleImageChange)
    }
    disconnect() {
        this.reader = null
    }
    fetchAddressInfo = async () => {
        try {
            const {data} = await axios.get(this.addresses_url)
            this.addresses = data;
        } catch (err) {
            throw err
        }
    }
    handleBarcodeScannerFromImage = async () => {
        const formats = [BarcodeFormat.CODE_128, BarcodeFormat.AZTEC];
        const hints = new Map()
        hints.set(DecodeHintType.POSSIBLE_FORMATS, formats)
        const codeReader = new BrowserMultiFormatReader();
        const imageEl = document.querySelector("#preview")
        return await codeReader.decodeFromImageElement(imageEl);

    }
    handleError = (err) => {
        //this.previewTarget.src = this.previewSrc;
        if (err.name === "NotFoundException") {
            return toastr.error("Unable to extract tracking data from barcode. please resubmit")
        }
        console.debug(err)
        return toastr.error(err.message)


    }
    handleImageChange = async(event) => {
        try {
            $("#shipitWaiter").removeClass("hidden")
            //get file and add as reader
            this.reader.readAsDataURL(event.target.files[0])
            // get current addresses information
            await this.fetchAddressInfo();
            // collect image submission form element
            const imageForm = this.element.querySelector("#parcel-image-form")
            const data = new FormData(imageForm)
            // create parcel_image row
            const req = await axios.post(
                this.new_parcel_image_url,
                data,
                {
                    headers:{
                        'Accept': 'application/json',
                        "Content-Type":'multipart/form-data'
                    },
                }
            )
            // extract image key from s3 response json
            const {image_key} = req.data
            // endpoint for sending s3 textract request
            const extractText = await axios
                .post(
                    this.property_parcel_images_extract_text_path,
                    {
                        authenticity_token:data.get("authenticity_token"),
                        filename: image_key
                    },
                    {
                        headers:{
                            'Accept': 'application/json',
                            "Content-Type":'multipart/form-data'
                        },
                    })
            const  {blocks} = extractText.data
            await this.reduceTextExtract(blocks);
            // compare actual address to extracted text
            await this.parseAddressDataFromTxtArr()
            // attempt to extract tracking from ocr text
            await this.parseTrackingNumberFromTxtArr()
            // read barcode only if parsing tracking number from text fails
            if(!this.barcodeTarget.value || !this.barcodeTarget.value === "") {
                const {text} = await this.handleBarcodeScannerFromImage()

                this.barcodeTarget.value=text;
                this.barcode_cloneTarget.value = text;

            }
            await this.validateParcelForm()

            $("#shipitWaiter").addClass("hidden")
            $("#view").removeClass("top-full").addClass("top-0")

        } catch (err){
            $("#shipitWaiter").addClass("hidden")
            this.handleError(err)
        }
    }
    handleReaderLoad = (event) => {
        const {target} = event;
        this.previewTarget.src = target.result
        this.imgurl = target.result
    }
    isMobile = () => {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        } else {
            return false
        }
    }
    parseTrackingNumberFromTxtArr = async () => {
        const flatText = this.splitLineArr
        const trackingConfirmations = this.trackingConfirmations()
        const expressions = [
            {name: 'ups', regex: /1Z[0-9A-Z]{16}/, isValid: trackingConfirmations._confirmUps},
            {name: 'ups', regex: /(H|T|J|K|F|W|M|Q|A)\d{10}/, isValid: trackingConfirmations._confirmUpsFreight},
            {name: 'fedex', regex: /\d{12}/, isValid: trackingConfirmations._confirmFedex12},
            {name: 'fedex', regex: /DT\d{12}/, isValid: trackingConfirmations._confirmFedexDoorTag}, //14
            {name: 'fedex', regex: /\d{15}/, isValid: trackingConfirmations._confirmFedex15},
            {name: 'fedex', regex: /02\d{18}/, isValid: trackingConfirmations._confirmFedexSmartPost}, //20
            {name: 'fedex', regex: /7489\d{16}/}, //20
            {name: 'fedex', regex: /6129\d{16}/}, //20
            {name: 'fedex', regex: /\d{20}/, isValid: trackingConfirmations._confirmFedex20}, //20
            {name: 'fedex', regex: /927489\d{16}/}, //22
            {name: 'fedex', regex: /926129\d{16}/}, //22
            {name: 'fedex', regex: /96\d{20}/, isValid: trackingConfirmations._confirmFedex9622}, //22
            {
                name: 'fedex', regex: [
                    "\\s*(?<ApplicationIdentifier>9\\s*6\\s*)",
                    "(?<SCNC>([0-9]\\s*){2})",
                    "([0-9]\\s*){5}",
                    "(?<GSN>([0-9]\\s*){10})",
                    "[0-9]\\s*",
                    "(?<SerialNumber>([0-9]\\s*){13})",
                    "(?<CheckDigit>[0-9]\\s*)"
                ].join(""), isValid: trackingConfirmations._confirmFedex34
            },
        ]
        const matches = flatText.reduce((acc, curr) => {
            // handle extra text in fedex tracking labels
            const txt = curr.replace(/\d (\d{3} \d{3} \d{4}) \d \d{2}/, "").replace(/\s/g,"")
            expressions.forEach(exp => {
                const m = txt.match(exp.regex)
                if (m) {
                    if (exp.isValid && exp.isValid(m[0])) {
                        acc.push({value: m[0], validated: true, name: exp.name})
                    } else if (curr.name === 'fedex' && !exp.isValid) {
                        acc.push({value: m[0], validated: false, name:exp.name})
                    }
                }
            })
            return acc
        }, [])
        const validatedResult = matches.find(i=>i.validated)

        if(validatedResult) {
            this.barcodeTarget.value = validatedResult.value
            this.barcode_cloneTarget.setAttribute("data-type", validatedResult.name)
            this.barcode_cloneTarget.value = this.formatTracker(validatedResult.name,validatedResult.value)}
        else if (matches[0]){
            this.barcodeTarget.value = matches[0].value
            this.barcode_cloneTarget.value = matches[0].value
        }

    }
    formatTracker = (type, str)=>{
        let _str = str.replace(/\s/g,"")
        if(type === 'ups'){
            let txt = ""
            let step = 0
            let spc = [2,5,8,10,14]
            for (let i=0;i<_str.length;i++){
                if(i=== spc[step] && i !== _str.length -1){txt += `${_str[i]} `; step++;}
                else{txt+= _str[i]}
            }
            return txt
        }
        if(type === 'fedex'){
            let txt = ""
            for(let i=0;i<_str.length;i++){
                if(i%4 === 0 && i !== _str.length -1 && i!==0) { txt += ` ${_str[i]}`}
                else { txt += _str[i] }
            }
            return txt
        }
        return str
    }
    parseAddressDataFromTxtArr = async () => {
        // split text from OCR reader
        const textArr = this.splitTextArr;

        // iterate through know address if match
        const matches = this.addresses.map((address) => {
            const {numeric, street_name, city, state, zip} = address
            address.matches = 0
            address.isMatch = false
            address.matched = {}
            const numericIdx = textArr.indexOf(textArr.find(v=>new RegExp(`^${numeric}`).test(v)))
            const streetArr = street_name.toUpperCase().split(" ")
            const streetIdxs = textArr.reduce((acc, curr, idx) => {
                streetArr.forEach((t) => {
                    if (curr === t) {
                        acc.push(idx)
                    }
                })
                return acc
            }, [])
            const cityIdx = textArr.indexOf(city.toUpperCase())
            const stateIdx = textArr.indexOf(state.toUpperCase())
            const zipIdx = textArr.indexOf(zip.toUpperCase())
            if (numericIdx !== -1) {
                address.matches++
                address.matched.numeric = textArr[numericIdx]
            }
            if (streetIdxs.length > 0) {
                address.matches++
                address.matched.street_name = streetIdxs.map(i => textArr[i]).join(" ")
            }
            /*
            if (cityIdx !== -1) {
                address.matches++
                address.matched.city = textArr[cityIdx]
            }
            if (stateIdx !== -1) {
                address.matches++
                address.matched.state = textArr[stateIdx]
                if (zipIdx !== -1) {
                    address.matches++
                    address.matched.zip = textArr[zipIdx]
                }
            }*/
            if (numericIdx !== -1 /*&& stateIdx !== -1 && cityIdx !== -1*/) {
                address.isMatch = true
            }
            return address
        })
        const firstMatch = matches.sort((a, b) => {
            if (a.matches < b.matches) return 1
            if (a.matches > b.matches) return -1
            return 0
        }).find(m => m.isMatch)
        if (firstMatch) {
            const {numeric = '', street_name = '', zip = '', city = '', state = ''} = firstMatch.matched
            this.property_address = firstMatch
            this.numericTarget.value = numeric
            this.street_nameTarget.value = street_name
            this.cityTarget.value = city
            this.stateTarget.value = state
            this.zipTarget.value = zip
            this.address_idTarget.value = firstMatch.id
        }

    }
    // Parse Textract Response to return only lines and words arrays
    reduceTextExtract = async(data) => {
        const reducer =   data.reduce((arr,curr)=>{
            if(curr.block_type === "LINE"){ arr.lines = [...arr.lines, curr.text.toUpperCase()]}
            if(curr.block_type === "WORD"){ arr.words = [...arr.words, curr.text.toUpperCase()]}
            return arr
        },{
            lines:[],
            words:[]
        })
        this.splitTextArr = reducer.words
        this.splitLineArr = reducer.lines
    }
    trackingConfirmations = () =>{
        const _checkDigit = function (trk, multipliers, mod) {
            var checkdigit, i, index, midx, ref, sum;
            midx = 0;
            sum = 0;
            for (index = i = 0, ref = trk.length - 2; (0 <= ref ? i <= ref : i >= ref); index = 0 <= ref ? ++i : --i) {
                sum += parseInt(trk[index], 10) * multipliers[midx];
                midx = midx === multipliers.length - 1 ? 0 : midx + 1;
            }
            if (mod === 11) {
                checkdigit = sum % 11;
                if (checkdigit === 10) {
                    checkdigit = 0;
                }
            }
            if (mod === 10) {
                checkdigit = 0;
                if ((sum % 10) > 0) {
                    checkdigit = 10 - sum % 10;
                }
            }
            return checkdigit === parseInt(trk[trk.length - 1]);
        }
        return {

            _confirmUps: function (trk) {
                var asciiValue, checkdigit, i, index, num, sum;
                sum = 0;
                for (index = i = 2; i <= 16; index = ++i) {
                    asciiValue = trk[index].charCodeAt(0);
                    if (asciiValue >= 48 && asciiValue <= 57) {
                        num = parseInt(trk[index], 10);
                    } else {
                        num = (asciiValue - 63) % 10;
                    }
                    if (index % 2 !== 0) {
                        num = num * 2;
                    }
                    sum += num;
                }
                checkdigit = sum % 10 > 0 ? 10 - sum % 10 : 0;
                if (checkdigit === parseInt(trk[17], 10)) {
                    return true;
                }
                return true;
            },
            _confirmUpsFreight: function (trk) {
                var firstChar, remaining;
                firstChar = `${(trk.charCodeAt(0) - 63) % 10}`;
                remaining = trk.slice(1);
                trk = `${firstChar}${remaining}`;
                if (_checkDigit(trk, [3, 1, 7], 10)) {
                    return [true, true];
                }
                return [false, false];
            },
            _confirmFedex12:function (trk) {
                if (_checkDigit(trk, [3, 1, 7], 11)) {
                    return [true, false];
                }
                return [false, false];
            },
            _confirmFedexDoorTag:function (trk) {
                if (_checkDigit(trk.match(/^DT(\d{12})$/)[1], [3, 1, 7], 11)) {
                    return [true, true];
                }
                return [false, false];
            },
            _confirmFedexSmartPost:function (trk) {
                if (_checkDigit(`91${trk}`, [3, 1], 10)) {
                    return [true, false];
                }
                return [false, false];
            },
            _confirmFedex15:function (trk) {
                if (_checkDigit(trk, [1, 3], 10)) {
                    return [true, false];
                }
                return [false, false];
            },
            _confirmFedex20:function (trk) {
                var alteredTrk;
                if (_checkDigit(trk, [3, 1, 7], 11)) {
                    return [true, false];
                } else {
                    alteredTrk = `92${trk}`;
                    if (_checkDigit(alteredTrk, [3, 1], 10)) {
                        return [true, false];
                    }
                }
                return [false, false];
            },
            _confirmFedex34:function (trk) {
                if (_checkDigit(trk.slice(-12), [1, 3], 10)) {
                    return [true, false];
                }
                if (_checkDigit(trk.slice(-12), [3,1,7], 11)) {
                    return [true, false];
                }
                return [false, false];
            },
            _confirmFedex9622:function (trk) {
                if (_checkDigit(trk, [3, 1, 7], 11)) {
                    return [true, false];
                }
                if (_checkDigit(trk.slice(7), [1, 3], 10)) {
                    return [true, false];
                }
                return [false, false];
            },

        }
    }
    validateParcelForm = async() => {
        /*
        if (!this.cityTarget.value || !this.stateTarget.value) {
            throw new Error("Cannot Read State and/or city from image. Please resubmit")
        }

         */
        if (!this.numericTarget.value) {
            throw new Error("Cannot find address from given text. Please resubmit image")
        }
        if (
            this.property_address && (this.numericTarget.value !== this.property_address.numeric ||
                !this.street_nameTarget.value
                    .split(" ")
                    .some(item => {
                        return this.property_address.street_name.toUpperCase().includes(item)
                    }))
        ) {
            throw new Error("Address does not match  property. Please resubmit image")
        }
    }

}