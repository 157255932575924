import 'jquery-validation'

export class JqueryValidator {
    constructor() {
        if($.validator) {
            $.validator.addMethod("strong-password", this.strongPassword.validator, this.strongPassword.message)
            $.validator.addMethod("phoneUS", this.phoneUS.validator, this.phoneUS.message)
        }
    }
    phoneUS={
        validatorRegExp: new RegExp(/^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/),
        validator: (value, element)=>{
            value = value.replace(/\s+/g, '');
            if(this.phoneUS.validatorRegExp.test(value)){
                return true
            }
            return false

        },
        message: (value, element)=>{
            if(!(this.phoneUS.validatorRegExp.test(value))){
                return "Please enter a valid US phone number"
            }
            return false;
        }
    }
    strongPassword = {
        validator: function (value, element) {
            let password = value;
            if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%&!])(.{8,20}$)/.test(password))) {
                return false;
            }
            return true;
        },
        message: function (value, element) {
            let password = $(element).val();
            if (!(/^(.{8,20}$)/.test(password))) {
                return 'Password must be between 8 to 20 characters long.';
            }
            else if (!(/^(?=.*[A-Z])/.test(password))) {
                return 'Password must contain at least one uppercase.';
            }
            else if (!(/^(?=.*[a-z])/.test(password))) {
                return 'Password must contain at least one lowercase.';
            }
            else if (!(/^(?=.*[0-9])/.test(password))) {
                return 'Password must contain at least one digit.';
            }
            else if (!(/^(?=.*[@#$%&!])/.test(password))) {
                return "Password must contain at least one special character from @#$%&!";
            }
            return false;
        }
    }
}