import {Controller} from "@hotwired/stimulus"
import axios from "axios";

const modal = (id, title) => `
<div class="bg absolute left-0 right-0 top-0 bottom-0 bg-grey-dk/50 z-10 backdrop-blur hidden " id="${id}">
  <div class="absolute bg-white/50 shadow-2xl rounded modal max-h-full top-4 bottom-4 flex flex-col">
    <div class="border-b-grey-xlt border-b relative bg-white">
      <h3 class="mr-16 min-h-16 pl-4">${title}</h3>
      <div class="w-16 right-0 absolute top-1/2 -mt-4 text-2xl text-center" id="${id}_close">
        <i class="fa fa-close"></i>
      </div>
    </div>
    <div class="p-4 overflow-y-auto content-body flex-grow" id="${id}_container">
      
    </div>
  </div>
</div>`
export default class extends Controller {
    modal_id;
    token = document.querySelector('meta[name="csrf-token"]').content;

    connect() {
        this.modal_id = this.data.get("id");
        $("body").append(modal(this.modal_id, "Create Smart Lock"))
        const screen = $(`#${this.modal_id}`)
        const currentModal = screen.find(".modal")
        currentModal
            .addClass("left-1/4 right-1/4")
        let closeButton = currentModal.find(`#${this.modal_id}_close`)
        closeButton.click(this.toggleModal)

    }

    toggleModal = () => {
        const ele = $(`#${this.modal_id}`)
        ele.hasClass('hidden') ? ele.removeClass("hidden") : ele.addClass("hidden")
        this.removeIframe()
        window.removeEventListener('message', this.handleIframeEvents)
    }
    create = async (event) => {
        try {
            const webview = await this.createNewWebview(event)
            this.appendIframe(webview.url)
            window.addEventListener('message', this.handleIframeEvents, false)
        } catch (err) {
            console.log(err)
            this.toggleModal()
            toastr.error(err.message)
        }
    }

    appendIframe = (src) => {
        const container = $(`#${this.modal_id}_container`)
        container.append(`
        <iframe 
            style="border: none;min-height: 600px;height: 100%;width: 100%"
            src="${src}" 
        />`)
    }
    removeIframe = () => {
        const container = $(`#${this.modal_id}_container`)
        container.empty()

    }
    handleIframeEvents = (event) => {
        const {current_pane_name} = event.data

        if (current_pane_name === 'finished_pane') {
            this.toggleModal()
        }

        if (event.data && event.data.event && event.data.event.event_type) {
            if(event.data.event.event_type === 'webview.login_successful'){
                location.reload(true)
            }
        }
        //{current_pane_name: 'brand_select_pane'}
        //current_pane_name:"login_pane
        //{current_pane_name: 'initiate_two_factor_pane'}
        //{current_pane_name: 'two_factor_pane'}
        //{
        //     "event": {
        //         "event_type": "webview.login_successful",
        //         "connect_webview_id": "3d4f5726-ee8f-4e1b-836f-7f2ac81b5728"
        //     }
        // }
        //{
        //     "current_pane_name": "finished_pane"
        // }
    }


    createNewWebview = async (event) => {
        const t = event.target
        const val = t.getAttribute("data-value")
        const name = t.getAttribute("data-display")
        const webviewId = await this.fetchWebviewId(event)
        let currentWebview;
        if (webviewId) {
            const seamWebview = await this.fetchWebview(webviewId)

            if (seamWebview.status === 'pending') {
                currentWebview = seamWebview;
            }
        }
        if (!currentWebview) {
            currentWebview = await this.createSeamWebview(val)
            await this.createSmartLockProvider(currentWebview, name, val)
        }

        return currentWebview

    }
    fetchWebviewId = async (event) => {
        const t = event.target
        //const val = t.getAttribute("data-value")
        const name = t.getAttribute("data-display")

        // Get all webviews for properties
        const providers = await this.fetchPropertyWebviews()
        const existingWebview = providers.find(p => p.provider.toLowerCase() === name.toLowerCase())
        if (existingWebview) {
            return existingWebview.connect_webview_id

        }
        return false

    }
    // TODO: pass in routes from controller
    createSeamWebview = async (provider) => {
        const req = await axios.post(`/seam/webview.json`,
            {accepted_providers: [provider]},
            {
                headers: {
                    'X-CSRF-Token': this.token,
                    'Content-Type': 'application/json'
                }
            })
        return req.data
    }
    fetchPropertyWebviews = async () => {
        const req = await axios.get("/smart_lock_providers.json", {
            headers: {
                'X-CSRF-Token': this.token,
                'Content-Type': 'application/json'
            }
        })
        return req.data
    }
    fetchWebview = async (uuid) => {
        const req = await axios.get(`/seam/webview/${uuid}.json`, {
            headers: {
                'X-CSRF-Token': this.token,
                'Content-Type': 'application/json'
            }
        })
        return req.data
    }

    createSmartLockProvider = async (webview, provider, provider_id) => {
        const req = await axios.post(
            '/smart_lock_providers.json',
            {...webview, provider, provider_id},
            {
                headers: {
                    'X-CSRF-Token': this.token,
                    'Content-Type': 'application/json'
                }
            })
        return req.data
    }

}